<template>
  <v-main>
    <AppBarPortal />
    <div class="mainContent">
      <div class="container-fluid pt-5 pb-5">
        <div class="row aen_lr_padding">
          <div class="col">
            <h1>Χρήσιμοι Σύνδεσμοι</h1>
            <!--
            <div class="list-group pt-2">
                <a href="https://www.ynanp.gr" target="_blank" class="list-group-item list-group-item-action"><span>Υπουργείο Ναυτιλίας και Νησιωτικής Πολιτικής (ΥΝΑΝΠ)</span><span>www.ynanp.gr</span></a>
                <a href="https://www.hcg.gr" target="_blank" class="list-group-item list-group-item-action">Αρχηγείο Λιμενικού Σώματος – Ελληνικής Ακτοφυλακής (Α.Λ.Σ.-ΕΛ.ΑΚΤ.)</a>
                <a href="https://www.imo.org/en" target="_blank" class="list-group-item list-group-item-action">International Maritime Organization</a>
                <a href="https://www.ugs.gr" target="_blank" class="list-group-item list-group-item-action">Ένωση Ελλήνων Εφοπλιστών</a>

                <a href="https://nee.gr" target="_blank" class="list-group-item list-group-item-action">Ναυτικό Επιμελητήριο Ελλάδος</a>
                <a href="https://www.eef.edu.gr" target="_blank" class="list-group-item list-group-item-action">Ίδρυμα Ευγενίδου</a>
                <a href="https://www.imo.org" target="_blank" class="list-group-item list-group-item-action">IMO</a>
                <a href="https://www.bimco.org" target="_blank" class="list-group-item list-group-item-action">BIMCO</a>

                <a href="https://www.eef.edu.gr/el/to-idryma/naytiki-ekpaideysi/imo-ambassador" target="_blank" class="list-group-item list-group-item-action">Πρεσβευτής του Διεθνούς Ναυτιλιακού Οργανισμού (ΙΜΟ)</a>
                <a href="https://mareduplus.ynanp.gr/" target="_blank" class="list-group-item list-group-item-action">Δημόσια Ναυτική Εκπαίδευση</a>
            </div>
            -->
            <ul class="pt-4">
              <li>
                Υπουργείο Ναυτιλίας και Νησιωτικής Πολιτικής (ΥΝΑΝΠ)<br />
                <a href="https://www.ynanp.gr" target="_blank">www.ynanp.gr</a>
              </li>
              <li>
                Αρχηγείο Λιμενικού Σώματος – Ελληνικής Ακτοφυλακής
                (Α.Λ.Σ.-ΕΛ.ΑΚΤ.)<br />
                <a href="https://www.hcg.gr" target="_blank">www.hcg.gr</a>
              </li>
              <li>
                Δημόσια Ναυτική Εκπαίδευση<br />
                <a href="https://mareduplus.ynanp.gr/" target="_blank"
                  >https://mareduplus.ynanp.gr/</a
                >
              </li>
              <li>
                Γραφείο Σταδιοδρομίας<br />
                <a href="https://hmco.hcg.gr/" target="_blank"
                  >https://hmco.hcg.gr/</a
                >
              </li>
              <li>
                Ναυτικό Επιμελητήριο Ελλάδος (ΝΕΕ)<br />
                <a href="https://nee.gr" target="_blank">https://nee.gr</a>
              </li>
              <li>
                Ίδρυμα Ευγενίδου<br />
                <a href="https://www.eef.edu.gr" target="_blank"
                  >https://www.eef.edu.gr</a
                >
              </li>
              <li>
                Πανελλήνια Ναυτική Ομοσπονδία (ΠΝΟ)<br />
                <a href="https://www.pno.gr/" target="_blank"
                  >https://www.pno.gr/</a
                >
              </li>
              <li>
                Ένωση Ελλήνων Εφοπλιστών (ΕΕΕ)<br />
                <a href="https://www.ugs.gr" target="_blank"
                  >https://www.ugs.gr</a
                >
              </li>
              <li>
                International Maritime Organization (IMO)<br />
                <a href="https://www.imo.org" target="_blank"
                  >https://www.imo.org</a
                >
              </li>
              <!--
              <li>
                  IMO<br>
                  <a href="https://www.imo.org" target="_blank">https://www.imo.org</a>
              </li>
              <li>
                  BIMCO<br>
                  <a href="https://www.bimco.org" target="_blank">https://www.bimco.org</a>
              </li>
              <li>
                  Πρεσβευτής του Διεθνούς Ναυτιλιακού Οργανισμού (ΙΜΟ)<br>
                  <a href="https://www.eef.edu.gr/el/to-idryma/naytiki-ekpaideysi/imo-ambassador" target="_blank">https://www.eef.edu.gr/el/to-idryma/naytiki-ekpaideysi/imo-ambassador</a>
              </li>
              -->
            </ul>
          </div>
          <!--
          <div class="col-xxl-4  col-xl-4 col-lg-5 col-md-6 col-sm-12">
              <img class="img-fluid" style="width: -webkit-fill-available;" src="./images/aen_map.jpg" alt="Σύστημα Ηλεκτρονικής Διαχείρισης Σπουδών Ακαδημιών Εμπορικού Ναυτικού (ΑΕΝ)">
          </div>
          -->
        </div>
      </div>
    </div>
    <AppFooterPortal />
  </v-main>
</template>

<script>
import AppBarPortal from "@/components/AppBarPortal.vue";
import AppFooterPortal from "@/components/AppFooterPortal.vue";

export default {
  name: "UsefulLinks",
  components: { AppFooterPortal, AppBarPortal },
};
</script>

<style scoped>
.aen_lr_padding {
  padding-left: 6rem;
  padding-right: 6rem;
}

@media only screen and (max-width: 1199px) {
  .aen_lr_padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  footer .row div {
    text-align: center;
  }

  .mainContent img {
    padding-top: 20px;
  }
}

span {
  float: left;
  clear: left;
}

.mainContent li {
  padding-top: 10px;
}

footer ul li a {
  font-size: 0.85rem !important;
}

.mainContent a {
  color: #2e4b93;
}

.mainContent p {
  text-align: justify;
}
</style>
